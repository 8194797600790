// Estilos utilizados pela DATABIT

.b-logo {
  margin-left: 3px;
  margin-right: 1px;
}

.bimg-logo {
  position: relative;
  left: 50px;
  top: 50px;
}

.b-logo-login {
  position: relative;
  margin-bottom: 0.8rem;
}

.borda-solida {
  border: 1px;
  border-style: solid;
}

.div_splash {
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
}

.div_logo {
  width: 800px;
  display: flex;
}

.div_login {
  width: 200px;
  display: flex;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div-login {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.externa {
  position: relative;
  width: 100%;
  height: 200px;
  background: #e4ee91;
}

.interna {
  left: 50%;
  top: 50%;
  margin-left: -100px;
  /* Metade do valor da Largura */
  margin-top: -50px;
  /* Metade da valor da Altura */
  position: absolute;
  width: 200px;
  /* Valor da Largura */
  height: 100px;
  /* Valor da Altura */
  background: #507701;
  color: #fff;
}

.center-login {
  margin-top: 40%;
  margin-right: 2em;
  border-radius: 2rem;
  margin-bottom: 60%;
  box-shadow: 1rem 1rem 1rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
}

.divBtn {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1100px) {
  .divBtn {
    flex-direction: column;
  }
}

body {
  font-size: 16px;
}

.header-databit {
  background: linear-gradient(to bottom, #ffff, #dddcdc);
}

.pcoded-mtext {
  color: rgb(255, 255, 255) !important;
}

.pcoded-micon {
  color: rgb(255, 255, 255) !important;
}

.nav-item {
  font-size: 14px !important;
}

.nav-item:hover {
  background: #3f4d67 !important;
  font-size: 14px !important;
  text-decoration-style: none !important;
  border-radius: 6px;
}

.pcoded-submenu {
  background: #04a9f5 !important;
  font-size: 14px !important;
}

.pcoded-navbar {
  background: #04a9f5 !important;
  font-size: 14px !important;
}

.pcoded-submenu:hover {
  background: #3f4d67 !important;
  font-size: 14px !important;
  text-decoration-style: none !important;
  border-radius: 6px;
}

.rodape {
  background: linear-gradient(to bottom, #ffff, #dddcdc);
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 0.3em;
}

.midias-sociais {
  position: relative;
  text-align: right;
  margin-right: 1em;
}

.site-databit {
  position: relative;
  margin-left: 0.5em;
  text-align: center;
}

.icon-media {
  text-align: center;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.titulo-modulo {
  font-size: 14px !important;
}

.divgrid {
  display: flex;
}

.my-swal {
  z-index: X !important;
}

.swal2-container.swal2-center.swal2-fade.swal2-shown {
  pointer-events: initial !important;
}

.testecor {
  font-size: 14px;
  background-color: '#04a9f5' !important;
}

.text-list {
  background-color: #d4e7fa !important;
}

.text-list2 {
  background-color: #ffffff !important;
}

.header-list {
  color: #0a0a0a !important;
  background-color: #eeeff0 !important;
}

.sidney {
  background-color: blue !important;
}

.text-required {
  background: #f4f7fa;
}

.border-total {
  border: 2px solid #eeeff0;
}

.divOS {
  border: 0.3px solid;
  border-color: black;
  height: auto;
  width: auto;
  margin-left: 0%;
  margin-top: 0px;
  border-radius: 10px;
}

.custom-lane {
  background-color: #e0e0e0;
  max-height: 450px;
  overflow-y: auto;
  border: 2px solid #4caf50;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}

.custom-datepicker {
  width: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-datepicker-popper {
  z-index: 1050 !important; /* Aumente o valor se necessário */
}

.div-dashboard {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  border: solid;
  border-width: 1px;
  width: 100%;
  margin-left: 1px;
  border-color: #0066ff;
}

.div-dashboard:hover {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  border: solid;
  border-width: 2px;
  width: 100%;
  margin-left: 1px;
  border-color: #757576;
  cursor: pointer;
}

.hidden-link {
  display: none;
}

.invisible-link {
  visibility: hidden;
}
