@import '~react-perfect-scrollbar/dist/css/styles.css';

@import '~nvd3/build/nv.d3.css';

@import 'ngx-perfect-scrollbar';

@import 'react-bootstrap/react-bootstrap';

.pcoded-header .main-search .input-group-text {
  line-height: 1;
}

.dropzone {
  .dz-progress {
    display: none;
  }
}

.text-muted {
  color: #6c757d !important;
}
